@import './colors';

.search-container {
    margin: 20px;
    padding: 20px; }

.activateBtn {
    border: 1px solid black;
    background-color: white;
    border-radius: 3px;
    &:hover {
        background-color: $blue;
        border-color: $blue;
        color: white; } }
