@import './login';
@import './search';

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: white; }

html {
  height: 100vh; }

#root {
  height: 100vh; }

.small-text {
  font-size: .9rem; }

.form-group {
  input {
    margin: .5rem 0; } }
